<template>
  <Reservation></Reservation>
</template>
<script>
import Reservation from "../components/Reservation.vue";
export default {
  name: "HotelReservation",
  components: {
    Reservation,
  },
};
</script>
