<template>
  <div>
    <Spinner v-if="fetching" />
    <div v-else class="container-fluid plr-80 payment-page">
      <div class="mb-5">
        <BookingEngineDateSelector
          :checkIn="formatDate(checkoutData.date.checkIn)"
          :checkOut="formatDate(checkoutData.date.checkOut)"
          @search="
            $router.push(
              `/listing/${$route.params.id}?checkIn=${$event.checkIn}&checkOut=${$event.checkOut}&findAvailability=true`
            )
          "
        />
      </div>

      <div class="card my-3">
        <div class="card-body px-md-5">
          <div
            class="tabs d-flex justify-content-between align-items-center py-3"
          >
            <div class="tab-section completed w-100">
              <div class="tab-icon"></div>
            </div>
            <div
              v-if="!noExtra"
              class="tab-section w-100"
              :class="{
                current: step === 'extra',
                completed: step === 'summary',
              }"
            >
              <p v-if="step === 'extra'" class="tab-label">Extra</p>
              <div class="tab-icon"></div>
            </div>
            <div
              class="tab-section w-100"
              :class="{ current: step === 'summary' }"
            >
              <p v-if="step === 'summary'" class="tab-label">Summary</p>
              <div class="tab-icon"></div>
            </div>
            <div class="tab-section">
              <div class="tab-icon"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 'extra'">
        <div class="extra-list mt-5">
          <div v-for="extra in extras" :key="extra.id" class="card my-4 extra">
            <div class="card-body row px-md-5">
              <div class="col-md-4">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <template v-if="extra.media.length">
                      <div>
                        <img
                          :src="extraImages(extra)[0].url"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </template>
                    <img
                      v-else
                      src="../assets/images/newspaper.svg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div>{{ extra.productDescription.name }}</div>
                </div>
              </div>
              <div class="col-md-2 my-auto">{{ extra.extraCode }}</div>
              <div class="col-md-2 my-auto" v-if="getExtraValue(extra.id)">
                <div>Quantity</div>
                <Multiselect
                  placeholder=""
                  style="width: 70px"
                  class="mt-3"
                  :options="cultswitchQtyOption(extra)"
                  :showLabels="false"
                  :searchable="false"
                  :allow-empty="false"
                  @select="extraQuantityChanged($event, extra)"
                  :value="getExtraValue(extra.id)"
                >
                </Multiselect>
              </div>
              <div class="text-main font-weight-bold col-md-2 my-auto">
                {{ extra.currencyCode }} {{ cultswitchProductPrice(extra) }}
              </div>
              <div class="col-md-2 text-right my-auto ml-auto">
                <button
                  v-if="!getExtraValue(extra.id)"
                  @click="addExtra(extra)"
                  class="btn btn-main px-5"
                >
                  + Add
                </button>
                <button
                  v-else
                  @click="removeExtra(extra)"
                  class="btn btn-main px-5"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="step === 'summary'">
        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <div class="row">
              <div class="col-md-6 py-2">
                <h5 class="mb-4">Your reservation</h5>
                <table class="table custom-table">
                  <tr>
                    <td>Total number of rooms</td>
                    <td>{{ roomsCounts }}</td>
                  </tr>
                  <tr>
                    <td>Total number of persons</td>
                    <td>{{ guestsCounts }}</td>
                  </tr>
                  <tr>
                    <td>Nights</td>
                    <td>{{ nightsCount }}</td>
                  </tr>
                </table>
              </div>
              <div class="col-md-6 py-2">
                <h5 class="mb-4">Travel Period</h5>
                <table class="table custom-table">
                  <tr>
                    <td>Check in</td>
                    <td>{{ checkoutData.date.checkIn }}</td>
                  </tr>
                  <tr>
                    <td>Check out</td>
                    <td>{{ checkoutData.date.checkOut }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4" v-if="checkoutData.distributorType === 'A'">
          <div class="card-body px-md-5 shadow-sm">
            <table class="table">
              <tr>
                <th>No. of rooms</th>
                <th>Total price</th>
                <th class="text-center">Persons per room</th>
                <th>Meal type</th>
                <th>Price per room</th>
                <th>Total</th>
                <th>Remove</th>
              </tr>
              <template v-for="(item, index) in checkoutData.cart">
                <tr
                  v-for="(guest, idx) in item.guests"
                  :key="`${item.id}-${idx}`"
                >
                  <td>{{ guest.guestCount }}x</td>
                  <td class="text-main">
                    <span>{{ item.product.productDescription.name }}</span
                    ><br />
                    <div>
                      <span
                        role="button"
                        v-b-popover.bottom.hover="
                          item.product.productDescription.description
                        "
                        >Rate details</span
                      >
                      | <span>Cancellation policy</span>
                    </div>
                  </td>
                  <td class="text-center">
                    <img
                      v-for="i in guest.guestCount"
                      :key="i"
                      src="../assets/icons/person.svg"
                      class="mr-1"
                      alt="person"
                    />
                  </td>
                  <td>
                    <template v-if="item.product.mealsIncluded">
                      <span>{{ item.product.mealsIncluded }}</span>
                    </template>
                    <template v-else> No meal included </template>
                  </td>
                  <td>
                    <span class="text-main"
                      >{{ item.product.currencyCode }} {{ guest.price }}</span
                    >
                  </td>
                  <td>
                    <span class="text-main"
                      >{{ item.product.currencyCode }} {{ guest.price }}</span
                    >
                  </td>
                  <td class="text-center">
                    <img
                      @click="removeRoom(index, idx)"
                      src="../assets/icons/trash.svg"
                      class="mr-1 cursor-pointer"
                      alt="trash"
                    />
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>

        <div class="card my-4" v-if="checkoutData.distributorType === 'B'">
          <div class="card-body px-md-5 shadow-sm">
            <table class="table">
              <tr>
                <th>No. of rooms</th>
                <th>Total price</th>
                <th class="text-center">Persons per room</th>
                <th>Meal type</th>
                <th>Price per room</th>
                <th>Total</th>
                <th>Remove</th>
              </tr>
              <tr
                v-for="(item, index) in checkoutData.cart"
                :key="`${item.id}-${index}`"
              >
                <td>{{ item.quantity }}x</td>
                <td class="text-main">
                  <span>{{ productName(item.product) }}</span
                  ><br />
                  <div>
                    <span
                      role="button"
                      v-b-popover.bottom.hover="productName(item.product)"
                      >Rate details</span
                    >
                    | <span>Cancellation policy</span>
                  </div>
                </td>
                <td class="text-center">
                  <img
                    v-for="i in item.guests"
                    :key="i"
                    src="../assets/icons/person.svg"
                    class="mr-1"
                    alt="person"
                  />
                </td>
                <td>
                  <template v-if="item.product.meal">
                    <span>{{ item.product.meal }}</span>
                  </template>
                  <template v-else> No meal included </template>
                </td>
                <td>
                  <span class="text-main"
                    >{{ item.currencyCode }} {{ item.price }}</span
                  >
                </td>
                <td>
                  <span class="text-main"
                    >{{ item.currencyCode }} {{ item.price * item.quantity }}</span
                  >
                </td>
                <td class="text-center">
                  <img
                    @click="removeRoom(index, idx)"
                    src="../assets/icons/trash.svg"
                    class="mr-1 cursor-pointer"
                    alt="trash"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          class="card my-4"
          v-if="checkoutData.extras && checkoutData.extras.length"
        >
          <div class="card-body px-md-5 shadow-sm">
            <table class="table">
              <tr>
                <th>No. of extras</th>
                <th>Extras</th>
                <th>Price per service</th>
                <th>Total</th>
                <th class="text-center">Remove</th>
              </tr>
              <tr v-for="item in checkoutData.extras" :key="item.id">
                <td>{{ item.quantity }}x</td>
                <td>
                  <span class="text-main">{{
                    item.extra.productDescription.name
                  }}</span>
                </td>
                <td>
                  <span class="text-main"
                    >{{ item.extra.currencyCode }} {{ item.price }}</span
                  >
                </td>
                <td>
                  <span class="text-main"
                    >{{ item.extra.currencyCode }}
                    {{ item.price * item.quantity }}</span
                  >
                </td>
                <td class="text-center">
                  <img
                    @click="removeExtraAndSave(item)"
                    src="../assets/icons/trash.svg"
                    class="mr-1 cursor-pointer"
                    alt="trash"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <ValidationObserver ref="observer">
          <div class="card my-4" ref="bookerDetails">
            <div class="card-body px-md-5 shadow-sm">
              <h5 class="mb-4">Your details</h5>
              <div class="row">
                <div class="col-md-5 my-3">
                  <ValidatedInput
                    name="first name"
                    v-model="bookerDetails.firstName"
                    placeholder="First name*"
                    rules="required"
                  />
                </div>
                <div class="col-md-5 my-3">
                  <ValidatedInput
                    name="last name"
                    v-model="bookerDetails.lastName"
                    placeholder="Last name*"
                    rules="required"
                  />
                </div>
                <div class="col-md-5 my-3">
                  <ValidatedInput
                    name="email"
                    type="email"
                    v-model="bookerDetails.email"
                    placeholder="Email address*"
                    rules="required|email"
                  />
                </div>
                <div class="col-md-5 my-3 no-border">
                  <ValidatedInput
                    name="phone"
                    type="tel"
                    v-model="bookerDetails.phone"
                    rules="required"
                  />
                </div>
                <div class="col-md-3 my-3">
                  <ValidatedInput
                    name="post code"
                    v-model="bookerDetails.postCode"
                    placeholder="Mandatory post code*"
                    rules="required"
                  />
                </div>
                <div class="col-md-3 my-3">
                  <ValidatedInput
                    name="city"
                    v-model="bookerDetails.city"
                    placeholder="City*"
                    rules="required"
                  />
                </div>
                <div class="col-md-3 my-3">
                  <ValidatedInput
                    name="Country"
                    type="country"
                    v-model="bookerDetails.country"
                    placeholder="Country*"
                    rules="required"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card my-4">
            <div class="card-body px-md-5 shadow-sm">
              <h5 class="mb-4">Guest details</h5>
              <div class="row">
                <div class="col-md-5 my-3">
                  <ValidatedInput
                    name="first name"
                    v-model="guestDetails.firstName"
                    placeholder="First name*"
                    rules="required"
                  />
                </div>
                <div class="col-md-5 my-3">
                  <ValidatedInput
                    name="last name"
                    v-model="guestDetails.lastName"
                    placeholder="Last name*"
                    rules="required"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card my-4">
            <div class="card-body px-md-5 shadow-sm">
              <div class="d-flex justify-content-between">
                <h5 class="mb-4">Company details (Optional)</h5>
                <div>
                  <button
                    @click="showCompanyDetails = !showCompanyDetails"
                    class="btn btn-outline-main px-5"
                  >
                    Open
                    <img
                      src="../assets/icons/open.svg"
                      class="mr-1"
                      alt="open"
                    />
                  </button>
                </div>
              </div>
              <div class="row" v-if="showCompanyDetails">
                <div class="col-md-10 my-3">
                  <input
                    class="form-control no-border"
                    type="text"
                    v-model="companyDetails.companyName"
                    placeholder="Company name"
                  />
                </div>
                <div class="col-md-10 my-3">
                  <input
                    class="form-control no-border"
                    type="text"
                    v-model="companyDetails.address1"
                    placeholder="Address 1"
                  />
                </div>
                <div class="col-md-10 my-3">
                  <input
                    class="form-control no-border"
                    type="text"
                    placeholder="Address 2 (Optional)"
                    v-model="companyDetails.address2"
                  />
                </div>
                <div class="col-md-3 my-3">
                  <input
                    class="form-control no-border"
                    type="text"
                    v-model="companyDetails.city"
                    placeholder="City"
                  />
                </div>
                <div class="col-md-3 my-3">
                  <input
                    class="form-control no-border"
                    type="text"
                    v-model="companyDetails.postCode"
                    placeholder="Mandatory post code"
                  />
                </div>
                <div class="col-md-3 my-3">
                  <ValidatedInput
                    name="Country"
                    type="country"
                    v-model="companyDetails.country"
                    placeholder="Country"
                    rules="required"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card my-4">
            <div class="card-body px-md-5 shadow-sm">
              <div class="d-flex justify-content-between">
                <h5 class="mb-4">Special requests (Optional)</h5>
                <div>
                  <button
                    @click="showSpecialRequest = !showSpecialRequest"
                    class="btn btn-outline-main px-5"
                  >
                    Open
                    <img
                      src="../assets/icons/open.svg"
                      class="mr-1"
                      alt="open"
                    />
                  </button>
                </div>
              </div>
              <div class="row" v-if="showSpecialRequest">
                <div class="col-md-10 my-3">
                  <ValidatedInput
                    type="textarea"
                    v-model="specialRequest"
                    placeholder="Do you have any special requests / wishes?"
                    rules=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card my-4" v-if="collectCardDetails">
            <div class="card-body px-md-5 shadow-sm">
              <h5 class="mb-4">Credit card details</h5>
              <p>
                Your credit card is only required to guarantee your reservation.
                Payment is made directly at the accommodation.
              </p>
              <div class="row">
                <div class="col-md-8 my-3">
                  <iframe
                    width="100%"
                    min-height="100vh"
                    id="pci_iframe"
                    :src="pciBookingUrl"
                    frameborder="0"
                    scrolling="no"
                  ></iframe>
                </div>
                <div class="col-md-4 px-3">
                  <img
                    src="../assets/images/pci-booking.svg"
                    class="img-fluid"
                    alt="PCI-Booking"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div class="my-1">
            <ValidationProvider rules="required|accepted" v-slot="{ errors }">
              <div :class="[{ 'input-error': errors.length }]">
                <input
                  name="terms and conditions"
                  type="checkbox"
                  class="mr-1"
                  v-model="termsAccepted"
                />
                With this booking I confirm the
                <a href="#" class="text-main">Terms & Conditions</a> and the
                <a href="#" class="text-main">Privacy Policy (GDPR)</a>
              </div>
              <span v-if="errors.length" class="text-danger small">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </div>

      <BookingCheckout
        ref="bookingCheckout"
        class="my-5"
        :type="checkoutData.distributorType"
        :cart="checkoutData.cart"
        :extras="checkoutData.extras"
        :sticky="false"
        :buttonText="step === 'extra' ? 'Summary' : 'Book Now'"
        @proceed="proceedWithBooking"
        :disabled="processingBooking"
        @clear="clearSelection"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Spinner from "./Widgets/Spinner.vue";
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import ValidatedInput from "./ValidatedInput.vue";
import BookingCheckout from "./Widgets/BookingCheckout.vue";
import BookingEngineDateSelector from "./Widgets/BookingEngineDateSelector.vue";
export default {
  name: "Reservation",
  components: {
    Spinner,
    Multiselect,
    ValidatedInput,
    BookingCheckout,
    BookingEngineDateSelector,
  },
  data() {
    return {
      fetching: false,
      step: "extra",
      noExtra: false,
      showCompanyDetails: false,
      showSpecialRequest: false,
      checkoutData: null,
      extras: [],
      pciBookingUrl: "",
      bookerDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        postCode: "",
        city: "",
        country: "",
      },
      guestDetails: {
        firstName: "",
        lastName: "",
      },
      companyDetails: {
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        postCode: "",
        country: "",
      },
      specialRequest: "",
      termsAccepted: false,
      collectCardDetails: true,
      processingBooking: false,
    };
  },
  async created() {
    this.fetching = true;
    const { id } = this.$route.params;
    let checkoutData = sessionStorage.getItem(`checkoutData-${id}`);
    checkoutData = JSON.parse(checkoutData) || null;
    if (
      !checkoutData ||
      moment(checkoutData.date.checkIn).isBefore(
        moment(new Date()).format("YYYY-MM-DD")
      )
    ) {
      this.$router.push(`/listing/${id}`);
    }
    this.checkoutData = checkoutData;

    if (checkoutData.distributorType === "A") {
      const { data } = await this.findCultswitchAvailability({
        check_in: checkoutData.date.checkIn,
        check_out: checkoutData.date.checkOut,
        property_id: id,
      });
      this.extras = data?.data?.extras || [];
      if (!this.extras.length) {
        this.step = "summary";
        this.noExtra = true;
      }
      this.collectCardDetails = false;
      this.checkoutData.cart.forEach((item) => {
        const { bookingGuaranteeCode } = item.product;
        if (bookingGuaranteeCode && bookingGuaranteeCode.toString() === "3") {
          this.collectCardDetails = true;
        }
      });
    } else {
      this.step = "summary";
    }

    this.fetching = false;
  },
  computed: {
    roomsCounts() {
      if (this.checkoutData.distributorType === "A") {
        return this.checkoutData.cart.reduce((accumulator, item) => {
          return accumulator + item.guests.length;
        }, 0);
      }
      return 1;
    },
    guestsCounts() {
      if (this.checkoutData.distributorType === "A") {
        let guests = 0;
        this.checkoutData.cart.forEach((item) => {
          item.guests.forEach(({ guestCount }) => (guests += guestCount));
        });
        return guests;
      }
      return 1;
    },
    nightsCount() {
      const { checkIn, checkOut } = this.checkoutData.date;
      return moment(checkOut, "YYYY-MM-DD").diff(
        moment(checkIn, "YYYY-MM-DD"),
        "days"
      );
    },
  },
  methods: {
    ...mapActions("booking", ["getPaymentCardForm", "processBooking"]),
    ...mapActions("property", ["findCultswitchAvailability"]),
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    handleMessageFromPciBooking(e) {
      if (e) {
        if (e.origin.toLowerCase() !== "https://service.pcibooking.net") {
          return;
        }
        if (e.data === "valid") {
          document
            .querySelector("#pci_iframe")
            .contentWindow.postMessage(
              "submit",
              "https://service.pcibooking.net"
            );
        } else if (e.data.startsWith("frameDimensionsChanged")) {
          const dims = e.data.split(":");
          const height = parseInt(dims[2]);
          document.querySelector("#pci_iframe").style.height = height + 5;
        }
      }
    },
    productName(availability) {
      const names = availability?.sellableUnit?.names;
      return names.length ? names[0]?.text : "";
    },
    async proceedWithBooking() {
      if (this.step === "extra") {
        const { id } = this.$route.params;
        sessionStorage.setItem(
          `checkoutData-${id}`,
          JSON.stringify(this.checkoutData)
        );
        this.step = "summary";
      } else if (this.step === "summary") {
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          if (this.collectCardDetails) {
            this.processingBooking = true;
            document
              .querySelector("#pci_iframe")
              .contentWindow.postMessage(
                "validate",
                "https://service.pcibooking.net"
              );
          } else {
            this.processingBooking = true;
            await this.completeBooking();
          }
          console.log("Form is valid! Submitting...");
        } else {
          this.$refs.bookerDetails.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    cultswitchProductPrice(product, guests = 1) {
      let prices = product.roomRates;
      if (prices[guests.toString()]) {
        return prices[guests.toString()].totalAmount;
      }
      prices = Object.values(prices);
      return prices.length ? prices[0].totalAmount : 0;
    },
    cultswitchQtyOption(extra) {
      return Array.from({ length: extra.numberOfUnits + 1 }, (_, i) => i);
    },
    addExtra(extra, quantity = 1) {
      if (quantity === 0) {
        this.removeExtra(extra);
        return false;
      }
      const idx = this.checkoutData.extras.findIndex(
        ({ id }) => extra.id === id
      );
      if (idx < 0) {
        this.checkoutData.extras.push({
          id: extra.id,
          quantity,
          price: this.cultswitchProductPrice(extra),
          extra,
        });
      } else {
        this.checkoutData.extras[idx]["quantity"] = quantity;
        this.checkoutData.extras[idx]["price"] = this.cultswitchProductPrice(
          extra,
          quantity
        );
      }
    },
    removeExtra(extra) {
      const idx = this.checkoutData.extras.findIndex(
        ({ id }) => extra.id === id
      );
      this.checkoutData.extras.splice(idx, 1);
    },
    getExtraValue(id) {
      const item = this.checkoutData.extras.find((extra) => extra.id === id);
      return item ? item.quantity : 0;
    },
    extraQuantityChanged(qty, extra) {
      this.addExtra(extra, qty);
    },
    removeRoom(cartIndex, guestIdx) {
      this.checkoutData.cart[cartIndex].guests.splice(guestIdx, 1);
      if (!this.checkoutData.cart[cartIndex].guests.length) {
        this.checkoutData.cart.splice(cartIndex, 1);
      }
      const { id } = this.$route.params;
      if (this.checkoutData.cart.length === 0) {
        sessionStorage.removeItem(`checkoutData-${id}`);
        this.$router.push(`/listing/${id}`);
      } else {
        sessionStorage.setItem(
          `checkoutData-${id}`,
          JSON.stringify(this.checkoutData)
        );
      }
    },
    extraImages(extra) {
      const images = {};
      extra.media.forEach((image) => {
        images[image.id] = image;
      });

      return Object.values(images).sort((a, b) => a.sortOrder - b.sortOrder);
    },
    removeExtraAndSave(extra) {
      this.removeExtra(extra);
      const { id } = this.$route.params;
      sessionStorage.setItem(
        `checkoutData-${id}`,
        JSON.stringify(this.checkoutData)
      );
    },
    async completeBooking(cardDetails = null) {
      const { data } = await this.processBooking({
        ...this.checkoutData,
        propertyId: this.$route.params.id,
        booker: this.bookerDetails,
        guest: this.guestDetails,
        company: this.companyDetails,
        note: this.specialRequest,
        payment: cardDetails,
      });
      this.processingBooking = false;
      sessionStorage.removeItem(`checkoutData-${this.$route.params.id}`);
      this.$router.push({
        path: "/reservation_success",
        query: {
          type: this.checkoutData.distributorType,
          reservationId: data.data.reservationId,
          total: this.$refs.bookingCheckout.totalAmount,
          currency: this.$refs.bookingCheckout.currencyCode,
          booker: this.bookerDetails.email,
          propertyId: this.$route.params.id
        },
      });
    },
    clearSelection() {
      const { id } = this.$route.params;
      sessionStorage.removeItem(`checkoutData-${id}`);
      this.$router.push(`/listing/${id}`);
    },
  },
  watch: {
    async step(value) {
      if (value === "summary") {
        const { data: response } = await this.getPaymentCardForm();
        this.pciBookingUrl = response?.data?.formDetails;
        if (window.addEventListener) {
          window.addEventListener("message", this.handleMessageFromPciBooking);
          window.addEventListener("cardTokenizationSuccess", async (event) => {
            this.completeBooking(event.detail);
            console.log("Tokenization Complete", event.detail);
          });
        } else if (window.attachEvent) {
          window.attachEvent("onmessage", this.handleMessageFromPciBooking);
        }
      }
    },
  },
};
</script>

<style>
#pci_iframe {
  width: 100%;
  min-height: 300px;
  height: 100%;
}
</style>
